import React, { useEffect, useState } from 'react'
import { Box, Container, CssBaseline, Fade, Link, ThemeProvider, Typography } from '@mui/material'
import { Color } from '../../../core/styles/colors/color'
import { theme } from '../../../core/styles/theme/typography/font'
import { styles } from '../../contacts/screens/Contact'
import {
  LocalPhoneOutlined as LocalPhoneOutlinedIcon,
  MailOutlined as MailOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
export const About = () => {
  const { t } = useTranslation()
  const [imageCount, setImageCount] = useState(2)

  const navigateToGoogleMaps = (): void => {
    open('https://maps.app.goo.gl/hXDAwvtG4vLoeNZBA', '_blank')
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setImageCount(prevValue => (prevValue + 1) % 6)
  //     setTimeout(() => {
  //       setImageCount(prevValue => (prevValue + 1) % 6)
  //     }, 555)
  //   }, 5555)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // <Fade in={imageCount === 0}>
  //   <Box
  //     component={'img'}
  //     sx={{
  //       width: '95%',
  //       height: height * 0.3,
  //       maxHeight: height * 0.6,
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       alignSelf: 'center',
  //       overflow: 'hidden',
  //       resize: 'cover',
  //     }}
  //     src={require('../../../assets/images/IMG-3026_cropped.png')}
  //   />
  // </Fade >
  // }, [])

  const renderImage = () => {
    return (
      <Box
        key={'img1_about'}
        component={'img'}
        sx={{
          width: { md: '50%', xs: '100%' },
          // maxHeight: 400,
          // height: height * 0.3,
          // maxHeight: { md: '50vh', xs: '35vh' },
          // justifyContent: 'center',
          // alignItems: 'center',
          alignSelf: 'center',
          overflow: 'hidden',
          resize: 'cover',
        }}
        src={require('../../../assets/images/gallery/optimized/CP_picture_06.webp')}
      />
    )
  }

  return (
    <Box sx={{ wiådth: '100%', p: 0, m: 0 }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={[{}]}>
          <Box sx={{ position: 'absolute', zIndex: 999, width: '100%' }}>
            <Typography
              align={'center'}
              // position={'absolute'}
              variant={'h4'}
              color={Color.WHITE}
              sx={{
                // alignSelf: 'center',
                // backgroundColor: 'blue',
                // display: 'flex',
                // alignItems: 'center',
                // ml: 3,
                pt: 5,
                // pr: 3,
                pb: 2,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {'„Cyberpool“ persikelia į naują vietą.'}
            </Typography>
            <Typography
              align={'center'}
              position={'relative'}
              variant={'h4'}
              color={Color.WHITE}
              sx={{
                // alignSelf: 'center',
                // backgroundColor: 'blue',
                // display: 'flex',
                // alignItems: 'center',
                // ml: 3,
                pr: 3,
                pb: 2,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {'Šiuo metu vyksta svetainės atnaujinimo darbai...'}
            </Typography>
            <Typography
              align={'center'}
              // position={'absolute'}
              variant={'h4'}
              color={Color.WHITE}
              sx={{
                // alignSelf: 'center',
                // backgroundColor: 'blue',
                // display: 'flex',
                // alignItems: 'center',
                // ml: 3,
                pr: 3,
                pb: 2,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {'JAU DIRBAME!!'}
            </Typography>
            <Box
              sx={{
                pr: 3,
                width: '100%',
                pb: 2,
                pt: 2,
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <LocationOnOutlinedIcon onClick={navigateToGoogleMaps} sx={styles.contactIconStylingCursor} />
              <Typography
                onClick={navigateToGoogleMaps}
                align={'center'}
                variant={'h5'}
                color={Color.WHITE}
                sx={[styles.contactHeaderLine, { mb: 0, cursor: 'pointer' }]}
              >
                {'Pavilnionių g. 55, Vilnius'}
              </Typography>
            </Box>

            <Box
              sx={{
                pr: 3,
                pt: 2,
                pb: 2,
                width: '100%',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <Link underline={'none'} href={`tel:${t('phoneNumber')}`}>
                <LocalPhoneOutlinedIcon sx={styles.contactIconStyling} />
                <Typography align={'center'} variant={'h5'} color={Color.WHITE} sx={[{ mb: 0 }]}>
                  {t('phoneNumber')}
                </Typography>
              </Link>
            </Box>
            <Box
              sx={{
                pr: 3,
                pt: 2,
                pb: 2,
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Link underline={'none'} href={`mailto:${t('cyberPoolInfoEmail')}`}>
                <MailOutlinedIcon sx={styles.contactIconStyling} />
                <Typography align={'left'} variant={'h5'} color={Color.WHITE} sx={styles.contactHeaderLine}>
                  {t('cyberPoolInfoEmail')}
                </Typography>
              </Link>
            </Box>
            <Typography
              align={'center'}
              // position={'absolute'}
              variant={'h4'}
              color={Color.WHITE}
              sx={{
                // alignSelf: 'center',
                // backgroundColor: 'blue',
                // display: 'flex',
                // alignItems: 'center',
                // ml: 3,
                pr: 3,
                pt: 2,
                pb: 2,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {'Biliardo valandos kaina - 15 eur'}
            </Typography>
          </Box>

          <Box
            sx={{
              // display: 'flex',
              width: '100vw',
              // height: { xs: '35vh', md: '35vh' },
              // width: '90vw',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              // position: 'absolute',
              zIndex: -999,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
              }}
            >
              {renderImage()}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  )
}

export default About
